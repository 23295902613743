import React from "react";

const Error = () => {
    return (
        <div>
            <p>Error 404: Page does not exist!</p>
        </div>
    );
};

export default Error;
